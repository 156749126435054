import { Directive } from '@angular/core';
import { EntityId, IChartingLibraryWidget, IChartWidgetApi, IDropdownApi } from '@chart/charting_library';
import * as moment from 'moment';

import { Colors, getTradeEntryChartObject, getTradeExitChartObject, MomentDateTimeFormats } from '@const';
import { TradesIndicatorOptions } from '@constants/indicators/trades-indicator.constants';
import { convertArrayToRecord } from '@u/utils';

@Directive()
export class TradesIndicator {
  private dropdownApi: IDropdownApi | null = null;
  private tradeIds: Array<EntityId> = [];

  public async drawDropdown(
    widget: IChartingLibraryWidget,
    dropdownOptions: TradesIndicatorOptions[],
    onDropdownUpdate: (option: TradesIndicatorOptions) => Promise<void>,
    getSelectedOption: () => TradesIndicatorOptions,
  ): Promise<void> {
    if (!widget) {
      return;
    }

    if (this.dropdownApi) {
      this.dropdownApi.remove();
    }

    const items = [...dropdownOptions].map((option) => ({
      title: option,
      onSelect: async (): Promise<void> => {
        this.dropdownApi.applyOptions({
          title: `Show Trades: ${option}`,
        });

        await onDropdownUpdate(option);
      },
    }));

    const selectedOption = getSelectedOption();

    this.dropdownApi = await widget.createDropdown({
      title: `Show Trades: ${selectedOption}`,
      items,
    });
  }

  public removeDropdown(): void {
    if (this.dropdownApi) {
      this.dropdownApi.remove();
    }
  }

  public async showTrades(
    activeChart: IChartWidgetApi,
    getSelectedValue: () => TradesIndicatorOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAllTrades: () => Promise<any>,
    getTimezone: () => string,
    isPowerX = false,
  ): Promise<void> {
    if (!activeChart) {
      return;
    }

    this.removeTrades(activeChart);

    const selectedValue = getSelectedValue();
    if (selectedValue === TradesIndicatorOptions.None) {
      return;
    }

    const allTrades = await getAllTrades();
    const timezoneName = getTimezone();
    const chartData = await activeChart.exportData();

    for (const entryBar of chartData.data) {
      const barDate = moment.unix(entryBar[0]).tz(timezoneName).format(MomentDateTimeFormats.ServerDate);
      const trade = allTrades[barDate];

      if (!trade) {
        continue;
      }

      const tradeEntryChartObject = getTradeEntryChartObject(trade, entryBar);
      const entryTradeId = await activeChart.createMultipointShape(
        [
          {
            time: tradeEntryChartObject.time,
            price: tradeEntryChartObject.price,
          },
        ],
        {
          shape: 'icon',
          overrides: {
            icon: tradeEntryChartObject.icon,
            size: 13,
            color: trade.is_filter_match || isPowerX ? tradeEntryChartObject.color : Colors.DarkGrey,
            linewidth: 1,
          },
          lock: true,
          zOrder: tradeEntryChartObject.zOrder,
          disableSelection: true,
          disableSave: true,
          disableUndo: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      );
      this.tradeIds.push(entryTradeId);

      if (trade.bar_exit_date) {
        const exitBar = chartData.data.find((e) => {
          const date = moment.unix(e[0]).tz(timezoneName).format(MomentDateTimeFormats.ServerDate);

          return date === trade.bar_exit_date;
        });

        if (!exitBar) {
          continue;
        }

        const tradeExitChartObject = getTradeExitChartObject(trade, exitBar);
        const exitTradeId = await activeChart.createMultipointShape(
          [
            {
              time: tradeExitChartObject.time,
              price: tradeExitChartObject.price,
            },
          ],
          {
            shape: 'icon',
            overrides: {
              icon: tradeExitChartObject.icon,
              size: 13,
              color: trade.is_filter_match || isPowerX ? tradeExitChartObject.color : Colors.DarkGrey,
              linewidth: 1,
            },
            lock: true,
            zOrder: tradeExitChartObject.zOrder,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
        );

        this.tradeIds.push(exitTradeId);
      }
    }
  }

  public removeTrades(chart: IChartWidgetApi): void {
    if (!chart) {
      return;
    }

    const allShapesRecord = convertArrayToRecord(chart.getAllShapes(), 'id');

    this.tradeIds.forEach((tradeId) => {
      if (allShapesRecord[tradeId]) {
        chart.removeEntity(tradeId);
      }
    });
  }

  public getShapesIds(): EntityId[] {
    return [...this.tradeIds];
  }
}
